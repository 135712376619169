import { APIgetSigleBlog } from '@/templates/api'
import BlogDetail from '@/templates/blogDetail'
import { useRequest } from 'ahooks'
import { Link } from 'gatsby'
import { Spin } from 'antd'
// styles
const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: '#8A6534',
  padding: 4,
  backgroundColor: '#FFF4DB',
  fontSize: '1.25rem',
  borderRadius: 4,
}

// markup
export default (props: { data: any; location: any }) => {
  console.log(9998, props)
  const { location, data } = props
  const searchTitle = location.pathname.split('/blog/detail/')[1]?.replace(/\/+$/, '') || ''

  const Page404 = () => {
    return (
      <main style={pageStyles}>
        <title>Not found</title>
        <h1 style={headingStyles}>Page not found</h1>
        <p style={paragraphStyles}>
          Sorry{' '}
          <span role='img' aria-label='Pensive emoji'>
            😔
          </span>{' '}
          we couldn’t find what you were looking for.
          <br />
          {process.env?.NODE_ENV === 'development' ? (
            <>
              <br />
              Try creating a page in <code style={codeStyles}>src/pages/</code>.
              <br />
            </>
          ) : null}
          <br />
          <Link to='/'>Go home</Link>.
        </p>
      </main>
    )
  }

  const blogRes = useRequest(() => APIgetSigleBlog(searchTitle), {
    ready: !!searchTitle,
    refreshDeps: [searchTitle],
  })
  if (blogRes.loading) {
    return <Spin spinning={blogRes.loading} />
  }
  const blogData = blogRes.data?.resultObject

  return blogData ? <BlogDetail data={{ ...data, imilePost: blogData }} /> : <Page404 />
}
