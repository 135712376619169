import Cookies from 'js-cookie'

// const testproxy="https://test-www.52imile.cn"
const testproxy=''

//  获取 相似 推荐博客
export const APIgetBlogs = async (data = {}) => {
    const url = `${testproxy}/flash/show/getBlogs`
    return window
      .fetch(url, {
        method: 'POST',
        headers: {
          lang: Cookies.get('EXPRESS_LANG'),
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          currentPage: 1,
          showCount: 2,
          ...data,
        }),
      })
      .then((res) => {
        return res.json()
      })
      .catch((err) => {
        return err
      })
  }


// 根据 searchTitle 获取博客
  export const APIgetSigleBlog = async (searchTitle: string) => {
    const url = `${testproxy}/flash/show/blog/title/${searchTitle}`
    return window
      .fetch(url, {
        method: 'get',
        headers: { 'Content-Type': 'application/json', lang: Cookies.get('EXPRESS_LANG') },
      })
      .then((res) => {
        return res.json()
      })
      .catch((err) => {
        return err
      })
  }
